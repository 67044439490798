
import React, { Component } from 'react';

import $ from 'jquery'

export default class News extends Component {

    constructor(props) {
      super(props);
      
    }
  
    componentDidMount() {
        const script = require('./Marquee');
        $("#marquee").marquee();
    }
  
    componentWillUnmount() {
      
    }
  
    render() {
        return (
        <ul id="marquee" class="marquee">
            {this.props.data.map( (data) => {
                return <li>
                    {data}
                </li>
            } )}
        </ul>
        );
    }
  }