import React from 'react'
import { Row, Col } from 'react-simple-flex-grid';

import newsData from '../data/news.yaml'


// Idea:
// Centered text with the year
// For each year: circle with day & month + text of the news


function groupBy(list, keyGetter) {
    const map = new Map();
    list.forEach((item) => {
         const key = keyGetter(item);
         const collection = map.get(key);
         if (!collection) {
             map.set(key, [item]);
         } else {
             collection.push(item);
         }
    });
    return map;
}

export default ({props}) => {

var dataNews = newsData.map( (val, index) => {
    var res = val
    res.date = new Date(val.date)
    return res
})
dataNews.sort( (a,b) => a.date < b.date )

var grouped = groupBy(dataNews, val => val.date.getFullYear());
grouped = Array.from(grouped)

return (
    grouped.map((val, index) => {
        return <div>
        <h2 style={{textAlign: 'center', margin: '0.5em 0 0.5em 0 !important'}}> {val[0]} </h2>
        <div style={{borderTop: '1px solid grey', paddingTop: '0.5rem'}}>
            {val[1].map((info, i) => {
                return <Row gutter={40} style={{marginBottom: '1rem'}}>
                    <Col xs={12} sm={2} md={1} lg={1} xl={1}>
                        <div style={{borderRadius: '50px', width: '100%', display: 'inline-block', 
                             textAlign: 'center', padding: '5px 0 5px 0px',
                             verticalAlign: 'middle'}}>
                            <h3>{info.date.getDate()} <br></br>
                            {info.date.toLocaleString('default', { month: 'short' })}</h3>
                        </div>
                    </Col>
                
                    <Col xs={12} sm={10} md={11} lg={11} xl={11}>
                        <div style={{textAlign: 'justify'}}> {info.text} </div>
                    </Col>
                </Row>
            })}
        </div>
        </div>
    })
    
    // 'EY'
)

// return (
//    grouped.keys().map((key, index) => {
//         console.log(key)
//         return (<div>
//             <h2> {key} </h2>
//             </div>
//         )
//     }) 
// )
}

                 
