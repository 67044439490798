import Img from 'gatsby-image';
import { chunk, sum } from 'lodash';
import React from 'react';
import { Box } from 'rebass';
import { Lightbox } from "react-modal-image";

import Portal from './Portal'

const Gallery = ({ images, itemsPerRow: itemsPerRowByBreakpoints }) => {
  const aspectRatios = images.map(image => image.aspectRatio);

  // For each breakpoint, calculate the aspect ratio sum of each row's images
  const rowAspectRatioSumsByBreakpoints = itemsPerRowByBreakpoints.map(
    itemsPerRow =>
      // Split images into groups of the given size
      chunk(aspectRatios, itemsPerRow).map(rowAspectRatios =>
        // Sum aspect ratios of images in the given row
        sum(rowAspectRatios),
      ),
  );

  const [showImageIndex, setShowImageIndex] = React.useState(undefined);
  const imgStyles = {
    css: {
      position: "absolute",
      left: 0,
      top: 0,
      width: "100%",
      height: "100%",
      transition: "transform 0.5s, filter 0.25s",
      "&:hover": {
        transform: "scale(1.1)",
        filter: "saturate(1.3)",
      },
    },
  };

  return (
    <div>
      {images.map((image, i) => {
       return( 
        <Box width={rowAspectRatioSumsByBreakpoints.map(
                // Return a value for each breakpoint
                (rowAspectRatioSums, j) => {
                  // Find out which row the image is in and get its aspect ratio sum
                  const rowIndex = Math.floor(i / itemsPerRowByBreakpoints[j]);
                  const rowAspectRatioSum = rowAspectRatioSums[rowIndex];
    
                  return `${(image.aspectRatio / rowAspectRatioSum) * 100}%`;
                },
              )}
            css={{ 
              display: 'inline-block',
              overflow: 'hidden',
              padding: '0px 5px 0px 5px'
            }}
            onClick={() => {
              setShowImageIndex(i);
            }}
        >
            <Img 
              fluid={image} 
              alt={"photo"} 
              css={{ 
                position: "absolute",
                left: 0,
                top: 0,
                width: "100%",
                height: "100%",
                transition: "transform 0.5s, filter 0.25s",
                ":hover": {
                  transform: "scale(1.1)",
                  filter: "saturate(1.3)",
                },
              }}
              />
        </Box>
      )})}
      {showImageIndex !== undefined && (
        <Portal>
          <Lightbox
            hideDownload={true}
            large={images[showImageIndex].publicURL}
            onClose={() => {
              setShowImageIndex(undefined);
            }}
          />
        </Portal>
      )} 
    </div>
  );
};

export default Gallery;
