import React from 'react'
import { useStaticQuery, graphql, Link } from "gatsby"
import Img from 'gatsby-image'


import { Row, Col } from 'react-simple-flex-grid';
// import ReactMarkdown from 'react-markdown/with-html'
import ReactMarkdown from 'react-markdown'

const path = (src, pre) => {
    if (src.includes('http')) {
        return src;
    } else {
        return pre + src;
    }
}

export default ({props}) => {
  const data = useStaticQuery(query);
  const thumbs = data.allFile; 

  return (
    data.allMdx.edges.map((data, index) => {
        const slug = data.node.fields.slug;
        data = data.node.frontmatter;
        return (
          <Row gutter={20}> 
            <Col xs={12} sm={3} md={3} lg={3} xl={3}>
              <span className="image main fling-kb" style={{'margin-top': '5px'}}>
                {/* <img src={path(data.thumb, slug)} alt="" style={{'border-radius': '10px'}} /> */}
                {
                  <Img fluid={(thumbs.edges.find(x => slug.includes(x.node.relativeDirectory) && x.node.relativePath.includes('thumb'))).node.childImageSharp.fluid} alt=""/>
                }
              </span>
            </Col>
            <Col xs={12} sm={9} md={9} lg={9} xl={9}>
              <h3 style={{'margin-bottom':'2px'}}>
                {data.hasPage && <Link to={slug}>{data.title}</Link>}
                {!data.hasPage && <a href={path(data.pdf, slug)}>{data.title}</a>}
              </h3>
              {/* <span style={{fontVariant: 'small-caps', fontSize: 'large'}}>{process_authors(data)}</span> <br></br> */}
              <span>
                {data.authors.map( (name, i) => {
                  return (<span> 
                    {(i != 0 && i < data.authors.length-1) && ', '}
                    {(i == data.authors.length-1) && ' and '}
                    <span style={{fontVariant: 'small-caps', fontSize: 'large'}}> {name} </span>
                    {data.authors_affiliation[i].includes('*') && '*'}
                  </span>)
                })}
              </span> <br></br>
              
              <span><em style={{color: '#DCDCDC'}}>{data.journal} , {data.year}</em></span>

              {data.jcr !== '' && <div className="badge-publication"><span className='badge-extra'>
                  {data.jcr}
                </span></div>}

              <div style={{marginTop: '5px'}}>
                {/* <span style={{'float': 'right', 'background-color': '#404040', 'border-radius': '5px', 'padding-left': '5px', 'padding-right': '5px'}}> */}
                {/* <span style={{'float': 'right', 'border': '2px solid #404040', 'border-radius': '5px', 'padding-left': '5px', 'padding-right': '5px'}}>
                  {data.jcr}
                </span> */}
    
                <ul class="publication_links" style={{paddingLeft: '0px'}}>
                  {/* {data.pdf !== '' && <li><a href={path(data.pdf, slug)}><i class="fa fa-file-pdf-o"></i> <span>PDF</span></a></li>}
                  {data.hasPage && <li><a href={slug}><i class="fa fa-home"></i> <span>Project page</span></a></li>}
                  {data.bibtex !== '' && <li><a href={path(data.bibtex, slug)}><i class="fa fa-quote-right"></i> <span>Bibtex</span></a></li>}
                  {data.video !== '' && <li><a href={path(data.video, slug)}><i class="fa fa-film"></i> <span>Video</span></a></li>}
                  {data.poster !== '' && <li><a href={path(data.poster, slug)}><i class="fa fa-picture-o"></i> <span>Poster</span></a></li>}
                  {data.code !== '' && <li><a href={path(data.code, slug)}><i class="fa fa-code"></i> <span>Code</span></a></li>}
                  {data.slides !== '' && <li><a href={path(data.slides, slug)}><i class="fa fa-file-powerpoint-o"></i> <span>Slides</span></a></li>}
                  {data.publisher !== '' && <li><a href={path(data.publisher, slug)}><i class="fa fa-book"></i> <span>Publisher version</span></a></li>}
                  {data.extra !== '' && <li><ReactMarkdown source={data.extra} escapeHtml={false} renderers={{paragraph: 'span'}}/></li>} */}
                  {data.pdf !== '' && <li><a className='button' href={path(data.pdf, slug)}><i class="fa fa-file-pdf"></i> <span>PDF</span></a></li>}
                  {data.hasPage && <li><Link className='button' to={slug}><i class="fa fa-home"></i> <span>Project page</span></Link></li>}
                  {data.bibtex !== '' && <li><a className='button' href={path(data.bibtex, slug)}><i class="fa fa-quote-right"></i> <span>Bibtex</span></a></li>}
                  {/* {data.video !== '' && <li><button href={path(data.video, slug)}><i class="fa fa-film"></i> <span>Video</span></button></li>} */}
                  {/* {data.poster !== '' && <li><button href={path(data.poster, slug)}><i class="fa fa-picture-o"></i> <span>Poster</span></button></li>} */}
                  {data.code !== '' && <li><a className='button' href={path(data.code, slug)}><i class="fa fa-code"></i> <span>Code</span></a></li>}
                  {/* {data.slides !== '' && <li><button href={path(data.slides, slug)}><i class="fa fa-file-powerpoint-o"></i> <span>Slides</span></button></li>} */}
                  {/* {data.publisher !== '' && <li><button href={path(data.publisher, slug)}><i class="fa fa-book"></i> <span>Publisher version</span></button></li>} */}
                  {data.extra !== '' && <li className="lineBreakMobile"><ReactMarkdown children={data.extra} escapeHtml={false} components={{'p': 'span'}}/></li>}
                </ul>
              </div>
            </Col>
          </Row>
        )
      })
  )
}

const query = graphql`
  query PubList {
    allMdx(
      filter: {
        fields: { collection: { eq: "publications" } }
        frontmatter: { published: { ne: false } }
      }
      sort: { fields: [frontmatter___date], order: DESC }
    ) {
      edges {
        node {
          id
          fields {
            slug
          }
          frontmatter {
            title
            rawDate: date
            date(formatString: "DD MMMM YYYY")
    
            authors
            authors_url
            authors_affiliation
            affiliation

            hasPage
    
            extra
            journal
            year
            jcr
            teaser
            thumb
            pdf
            webpage
            bibtex 
            video
            presentation
            poster
            code
            slides
            publisher
          }
          excerpt
          timeToRead
        }
      }
    }
  
  allFile(filter: {extension: {regex: "/(jpg)|(jpeg)|(png)/"}}) 
    {
      edges {
        node {
          relativePath
          relativeDirectory
          sourceInstanceName
          childImageSharp {
            fluid {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }

  }
`;
