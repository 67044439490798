import React from 'react'

const LogoRGL = ({ fill }) => (
    <svg
        viewBox="0 0 126 55"
        fill={{fill}}>

<path style={{fill: fill}} d="M45.85,48.97c-3.79,0-7.59-0.06-11.38,0.03c-1.27,0.03-1.49-0.83-1.87-1.62c-2.04-4.26-4.14-8.49-6.03-12.82
	c-0.7-1.61-1.67-2.11-3.24-1.85c-1.78,0.3-0.94,1.71-0.96,2.6c-0.1,3.99-0.09,7.98-0.02,11.97c0.02,1.26-0.28,1.77-1.64,1.72
	c-2.66-0.1-5.32-0.08-7.99-0.01c-1.11,0.03-1.4-0.38-1.39-1.43c0.03-13.72,0.03-27.43,0-41.15c0-1.12,0.39-1.39,1.44-1.38
	c5.57,0.06,11.16-0.17,16.72,0.11c9.36,0.48,15.02,7.03,14.11,15.9c-0.37,3.63-2.19,6.5-5.09,8.48c-1.65,1.13-1.44,1.87-0.65,3.33
	c2.53,4.66,4.91,9.4,7.33,14.12C45.48,47.54,46,48.04,45.85,48.97z"/>
<path style={{fill: fill}} d="M49.48,26.95c0-2.24-0.12-4.5,0.02-6.73c0.61-9.59,6.71-15.36,16.32-15.53c8.64-0.15,14.91,4.55,16.91,12.54
	c0.42,1.67,0.33,2.46-1.67,2.27c-1.57-0.15-3.16-0.08-4.74-0.02c-2.39,0.09-4.52,0.26-5.7-2.76c-0.83-2.12-3.32-2.36-5.45-2.09
	c-2.14,0.27-3.79,1.52-4.19,3.71c-1.06,5.77-1.08,11.59,0.01,17.34c0.52,2.76,2.92,4.05,5.91,3.74c2.95-0.31,4.93-2.34,5.17-5.14
	c0.09-1.03-0.05-1.44-1.23-1.53c-1.64-0.14-3.86,1.01-4.79-0.66c-0.71-1.27-0.17-3.25-0.19-4.91c0-0.5,0.06-1.01-0.01-1.5
	c-0.26-1.76,0.45-2.26,2.2-2.21c4.33,0.12,8.66,0.13,12.98,0c1.8-0.06,2.26,0.53,2.15,2.23C83,28.29,83.12,30.88,83,33.45
	c-0.48,10.3-8.04,16.79-18.31,15.78c-9.6-0.94-14.59-6.1-15.21-15.8c-0.14-2.15-0.02-4.32-0.02-6.48
	C49.47,26.95,49.47,26.95,49.48,26.95z"/>
<path style={{fill: fill}} d="M90.3,26.88c0-6.65,0.05-13.3-0.04-19.96c-0.02-1.55,0.41-2.01,1.94-1.92c2.24,0.13,4.5,0.17,6.74-0.01
	c1.88-0.15,2.4,0.46,2.38,2.33c-0.09,9.73,0.02,19.46-0.09,29.18c-0.02,2,0.36,2.71,2.53,2.62c4.65-0.19,9.32-0.01,13.98-0.09
	c1.39-0.02,1.91,0.35,1.84,1.8c-0.11,2.24-0.08,4.49-0.01,6.73c0.04,1.09-0.31,1.41-1.4,1.4c-8.82-0.04-17.64-0.06-26.46,0.01
	c-1.64,0.01-1.41-0.93-1.41-1.91C90.3,40.35,90.3,33.62,90.3,26.88z"/>
<path style={{'fill-opacity': "1.0"}} d="M22.33,19.09c0-4.15,0-4.15,4.11-4.14c0.41,0,0.83-0.01,1.24,0.01c3.07,0.11,4.87,1.59,4.95,4.08
	c0.09,2.57-1.79,4.27-4.9,4.44c-0.33,0.02-0.66,0-1,0.01C22.33,23.5,22.33,23.5,22.33,19.09z"/>
</svg>
);

export default LogoRGL;
