import React from 'react'

import projectsData from '../data/projects.yaml'

import { useStaticQuery, graphql } from "gatsby"

import { Row, Col } from 'react-simple-flex-grid';
// import ReactMarkdown from 'react-markdown/with-html'
import ReactMarkdown from 'react-markdown'
import Img from 'gatsby-image'

import "../assets/scss/card.scss"

const path = (src, pre) => {
    if (src.includes('http')) {
        return src;
    } else {
        return pre + src;
    }
}

export default ({props}) => {
    const dataFile = useStaticQuery(query);
    const projectsData = dataFile.allProjectsYaml.edges; 
return (
    // <div className="cards">
    //       {scenesData.map((data, index) => {
    //         return (
    //           <SceneCard title={data.title} thumb={data.thumb} author={data.author} scene_url={data.scene_url} author_url={data.author_url} links={data.links}/>
    //         )
    //       })}
    // </div>
    <div className="two-columns">
    {/* <ul style={{columnCount: 2}}> */}
        {projectsData.map((data2, index) => {
            const data = data2.node;
            return (
                <div>
                    <div>
                    <span className="image main fling-kb" style={{'margin-top': '5px'}}>
                        {data.image !== '' && <center><Img fluid={data.image.childImageSharp.fluid} alt="" style={{'border-radius': '10px', 'max-width':'80%'}} /></center>}
                    </span>
                    </div>
                    <div>
                    <h3 style={{'margin-bottom':'2px', 'text-transform': 'none', paddingLeft: '20px'}}><ReactMarkdown children={data.name} escapeHtml={false} components={{'p': 'span'}}/></h3>

                    {data.description !== '' && <div style={{marginTop: '0.5rem', padding: '0px 20px 0px 20px'}}>
                        <ReactMarkdown children={data.description} escapeHtml={false} components={{'p': 'span'}}/>
                    </div>}
                    
        
                    <div style={{marginTop: '5px', paddingLeft: '20px'}}>
                        <ul class="publication_links" style={{paddingLeft: '0px'}}>
                            {data.url !== '' && <li style={{paddingLeft: '0px', marginRight: '0.5rem'}}><a className='button' style={{fontWeight: 'bold'}} href={data.url}><i class="fab fa-chrome"></i> <span>Link</span></a></li>}   
                            {data.code !== '' && <li style={{paddingLeft: '0px', marginRight: '0.5rem'}}><a className='button' style={{fontWeight: 'bold'}} href={data.code}><i class="fab fa-github"></i> <span>Code</span></a></li>}
                        </ul>
                    </div>
                    </div>
                </div>
            )
          })}
    {/* </ul> */}
    </div>
)
}

const query = graphql`
query ProjectLists 
    {
        allProjectsYaml {
            edges {
            node {
                name
                description
                url
                code
                image {
                childImageSharp {
                    fluid {
                    ...GatsbyImageSharpFluid
    }}}}}}
}`;
