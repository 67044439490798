import React from 'react'
import { useStaticQuery, graphql } from "gatsby"

import Masonry from 'react-masonry-css'

export default ({props}) => {
    const dataFile = useStaticQuery(query);
    const linksData = dataFile.allLinksYaml.edges; 
    return (
        <Masonry
            breakpointCols={{default: 2, 700: 1}}
            className="my-masonry-grid"
            columnClassName="my-masonry-grid_column">
        {
            linksData.map((data2, index) => {
                const data = data2.node;
                return (
                    <div className="links-card">
                    <h4 style={{padding: '10px 10px 5px 10px', marginBottom: '0.25rem', borderBottom: '1px solid rgba(255, 255, 255, 0.144)'}}> <i className="fa fa-link"/> {data.name} </h4>
                    <ul style={{marginBottom: '5px', paddingLeft: '2rem'}}>
                        {
                            data.links.map((link, index) => {
                                return (<li>
                                    <a href={link.url}> 
                                        {link.name}  
                                        {link.extra != null && <span style={{fontFamily: 'monospace'}}> [{link.extra}] </span>}
                                    </a>
                                </li>)
                            })
                        }
                    </ul>
                    </div>
                )
            })
        }
        </Masonry>
    )
}

const query = graphql`
query LinksQuery {
    allLinksYaml {
      edges {
        node {
          name
          links {
            name
            url
            extra
          }
        }
      }
    }
  }`;
