import React from 'react'
import { useStaticQuery, graphql, Link } from "gatsby"
import Img from 'gatsby-image'
import { Row, Col } from 'react-simple-flex-grid';

import "../assets/scss/blog-card.scss"

const path = (src, pre) => {
    if (src.includes('http')) {
        return src;
    } else {
        return pre + src;
    }
}

function formatDate(date) {
  let monthNames =["Jan","Feb","Mar","Apr",
                    "May","Jun","Jul","Aug",
                    "Sep", "Oct","Nov","Dec"];
  
  let day = date.getDate();
  
  let monthIndex = date.getMonth();
  let monthName = monthNames[monthIndex];
  
  let year = date.getFullYear();
  
  return `${day} ${monthName} ${year}`; 
}

function groupBy(list, keyGetter) {
  const map = new Map();
  list.forEach((item) => {
       const key = keyGetter(item);
       const collection = map.get(key);
       if (!collection) {
           map.set(key, [item]);
       } else {
           collection.push(item);
       }
  });
  return map;
}

export default ({props}) => {
    const data = useStaticQuery(query);
    const thumbs = data.allFile; 

    var dataPosts = data.allMdx.edges.map( (val, index) => {
        console.log(val)
        var res = val
        res.node.frontmatter.date = new Date(val.node.frontmatter.date)
        return res
    })
    // dataPosts.sort( (a,b) => a.date < b.date )

    var grouped = groupBy(dataPosts, data => data.node.frontmatter.date.getFullYear());
    grouped = Array.from(grouped)


    return (
    // grouped.map((data, index) => {
    //     const slug = data.node.fields.slug;
    //     const dataPost = data.node.frontmatter;
    //     const socialImageUrl = 'url(' + path(dataPost.socialMedia, slug) + ')'
    //     return (
    //         <div>{formatDate(dataPost.date)}</div>
    //     )
    //   })

      grouped.map((val, index) => {
        return <div>
        <div className='leaders'>
            <div>
            <span className='h3 title'>{val[0]}</span>
            </div>
        </div>
        <div>
          <ul>
            {val[1].map((info, i) => {
              const slug = info.node.fields.slug;
              const dataPost = info.node.frontmatter;
              const socialImageUrl = 'url(' + path(dataPost.socialMedia, slug) + ')'
              return (
                <li style={{listStyle: 'none', marginBottom: '0.5rem'}}>
                  <Row>
                    <Col xs={12} sm={10} md={9} lg={9} xl={9}>
                      <Link style={{color: 'peru', fontFamily: 'monospace'}} to={slug}> <i class="fa fa-bolt"></i> {dataPost.title} </Link>
                    </Col>
                    <Col xs={12} sm={10} md={3} lg={3} xl={3}>
                      <div style={{float: 'right'}}><span> <i class="fa fa-calendar" style={{'margin-right': '10px'}}></i> <span style={{'background-color': '#404040', 'border-radius': '5px', 'padding-left': '5px', 'padding-right': '5px', fontFamily: 'monospace'}}>{formatDate(dataPost.date)}</span></span></div>
                    </Col>
                  </Row>
                </li>
              )
            })}
          </ul>
        </div>
        </div>
    })
    )
}

const query = graphql`
  query BlogList_ {
    allMdx(
      filter: {
        fields: { collection: { eq: "blog" } }
        frontmatter: { published: { ne: false } }
      }
      sort: { fields: [frontmatter___date], order: DESC }
    ) {
      edges {
        node {
          id
          fields {
            slug
          }
          frontmatter {
            title
            rawDate: date
            date
            tags
            socialMedia
          }
          excerpt
          timeToRead
        }
      }
    }

    allFile(filter: {extension: {regex: "/(jpg)|(jpeg)|(png)/"}}) 
    {
      edges {
        node {
          relativePath
          relativeDirectory
          sourceInstanceName
          childImageSharp {
            fluid {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }

  }
`;
