import React from 'react'

const LogoEPFL = ({ fill }) => (
    <svg
        viewBox="0 0 182.4 53"
        fill={{fill}}>

  <path
     id="polygon8"
     d="M 0,21.6 H 11.43 V 9.8 H 38.34 V 0 H 0 Z"
     style={{fill: fill}} />
  <path
     id="polygon10"
     d="M 0,53 H 38.34 V 43.2 H 11.43 V 31.4 H 0 Z"
     style={{fill: fill}} />
  <path
     id="rect12"
     d="m 11.43,21.6 h 24.610001 v 9.800001 H 11.43 Z"
     style={{fill: fill}} />
  <path
     style={{fill: fill}}
     id="path14"
     d="M86,4.87a16.12,16.12,0,0,0-5.68-3.53A23.76,23.76,0,0,0,71.82,0H48.14V53H59.57V31.4H71.82a23.76,23.76,0,0,0,8.46-1.34A16.12,16.12,0,0,0,86,26.53a13.43,13.43,0,0,0,3.19-5,17.38,17.38,0,0,0,0-11.62A13.52,13.52,0,0,0,86,4.87ZM78,18.73a5.7,5.7,0,0,1-2.26,1.8,11.33,11.33,0,0,1-3.27.85,32,32,0,0,1-3.86.22H59.57V9.8h9.05a32,32,0,0,1,3.86.22,11,11,0,0,1,3.27.86A5.59,5.59,0,0,1,78,12.67a5,5,0,0,1,.86,3A5,5,0,0,1,78,18.73Z"
     class="cls-1" />
  <path
     id="polygon16"
     d="M 155.47,43.2 V 0 h -11.43 v 53 h 38.34 v -9.8 z"
     style={{fill: fill}} />
  <path
     id="polygon18"
     d="m 97.42,21.6 h 11.43 V 9.8 h 26.91 V 0 H 97.42 Z"
     style={{fill: fill}} />
  <path
     id="rect20"
     d="M 97.419998,31.4 H 108.85 V 53 H 97.419998 Z"
     style={{fill: fill}} />
  <path
     id="rect22"
     d="m 108.85,21.6 h 24.61 v 9.800001 h -24.61 z"
     style={{fill: fill}} />
</svg>
);

export default LogoEPFL;
