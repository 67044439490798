import React from 'react'

const LogoGILAB = ({ fill }) => (
    <svg
        viewBox="0 0 146.09 181.14"
        fill={{fill}}>

<path style={{fill: fill}} d="M28.46,112.93c4.6,1.56,9.23,3.05,14.01,3.97c3.8,0.73,7.62,0.56,11.45,0.45c2.95-0.08,5.9-0.14,8.83,0.39
	c5.26,0.96,8.38,4.36,9.03,9.72c0.57,4.74-0.25,9.3-1.6,13.81c-0.26,0.85-0.14,1.19,0.72,1.51c3.95,1.5,7.87,3.06,11.78,4.64
	c0.68,0.28,1.11,0.27,1.6-0.37c0.77-1.02,1.77-1.83,2.84-2.55c1.53-1.02,3.18-1.41,5-1.09c2.25,0.41,4.51,0.49,6.79,0.3
	c0.78-0.07,1.27-0.33,1.62-0.98c1.57-2.91,2.05-5.79,0.17-8.83c-1.92-3.12-3.82-6.27-5.45-9.55c-0.28-0.57-0.52-1.16-0.81-1.73
	c-1.53-2.98-1.68-5.85,0.27-8.8c1.24-1.88,2.24-3.95,3.04-6.09c0.37-1,0.92-2.13,0.11-3.06c-0.87-0.99-2.13-0.72-3.26-0.41
	c-3.26,0.89-6.44,2.11-9.78,2.7c-5.05,0.9-10.1,0.54-15.14-0.11c-1.42-0.18-2.83-0.44-4.56-0.71c2.78-0.48,5.26-0.86,7.72-1.34
	c10.99-2.11,21.04-6.31,29.72-13.51c3.44-2.85,6.53-6,8.33-10.19c0.33-0.77,0.61-1.56,0.82-2.37c0.24-0.95-0.13-1.38-1.12-1.3
	c-1.8,0.15-3.32,1.06-4.89,1.83c-0.48,0.23-0.89,0.67-1.63,0.62c3.24-3.17,6.08-6.47,8.46-10.18c3.2-4.99,4.13-10.37,3.15-16.16
	c-0.42-2.52-1.81-4.17-4.17-5.1c-1.26-0.5-2.5-1.05-3.72-1.64c-2.55-1.23-3.91-3.27-4.17-6.1c-0.36-3.82-2.32-5.79-6.15-6.29
	c-0.41-0.05-0.46,0-0.61,0.48C95.01,40.74,92.42,46.13,86,48.04c-5.28,1.57-14.96-0.05-19.17-7.93c-0.36-0.68-0.58-0.73-1.21-0.33
	c-1.8,1.12-3.56,2.29-5.23,3.59c-1.81,1.41-3.05,3.21-3.73,5.43c-0.92,3.03-2.37,3.62-5.19,2.11c-3.23-1.73-5.88-4.24-8.64-6.6
	c-2.28-1.95-4.49-3.96-6.74-5.94c-0.16-0.14-0.27-0.41-0.63-0.31c0.98,3.23,2.05,6.43,2.67,9.75c0.58,3.14,0.82,6.33,1.54,9.45
	c0.16,0.7,0.36,1.39,0.59,2.07c1.36,4.02,4.52,5.38,8.42,5.71c2.56,0.22,4.67-0.74,6.87-2.46c-1.48,4.34-3,8.33-7.03,10.51
	c-3.5,1.89-6.88,0.9-10.05-1.09c-1.13-0.71-1.08-0.72-1.53,0.59c-0.67,1.98-0.31,3.95-0.06,5.93c0.04,0.32,0.08,0.63,0.13,0.95
	c0.23,1.52-0.25,2.58-1.58,3.54c-5.69,4.11-11.46,8.13-16.92,12.55c-1.27,1.03-2.52,2.1-3.42,3.5c-1.07,1.67-0.74,2.83,1.06,3.66
	c2.51,1.15,5.25,1.32,7.93,1.61c3.49,0.37,6.99,0.62,10.5,0.89c2.25,0.17,4.47-0.07,6.59-0.87c2.52-0.96,4.39-2.64,5.22-5.24
	c0.57-1.76,1.7-2.67,3.47-2.78c1.88-0.12,3.57,0.34,4.68,2.05c0.52,0.8,1.01,1.63,1.55,2.41c1.2,1.74,1.13,2.39-0.61,3.66
	c-2.82,2.07-5.89,3.65-9.32,4.45c-6.21,1.45-12.45,1.78-18.76,0.68c-5.27-0.91-10.51-1.91-15.6-3.55c-1.9-0.61-3.76-1.32-5.45-2.42
	c-2.05-1.33-2.3-2.28-1.03-4.32c1.96-3.14,4.52-5.79,7.07-8.42c4.53-4.69,9.4-9,14.48-13.08c1.89-1.52,3.04-3.31,2.88-5.84
	c-0.11-1.73,0.57-3.29,1.44-4.74c0.81-1.35,0.93-2.69,0.52-4.23c-1.23-4.54-2.62-9.08-2.97-13.78c-0.66-8.63-3.01-16.85-5.57-25.04
	c-0.13-0.4-0.27-0.8-0.42-1.22c0.5-0.17,0.8,0.25,1.15,0.45c4.53,2.62,8.72,5.75,13.09,8.61c3.88,2.54,8.02,4.58,12.32,6.31
	c0.43,0.17,0.77,0.42,1.2-0.17c2.67-3.67,6.33-6.17,10.22-8.31c1.05-0.58,1.12-1.12,0.89-2.13c-1.63-7.07-4.63-13.64-7.44-20.28
	c-0.79-1.87-1.63-3.72-2.45-5.58c0.37-0.24,0.66,0.1,0.95,0.25c5.93,3.02,12,5.73,18.22,8.12c5.14,1.98,9.83,4.74,13.96,8.41
	c0.45,0.4,0.86,0.45,1.4,0.37c7.98-1.29,15.73-0.54,23.21,2.61c0.38,0.16,0.7,0.4,1.17-0.01c3.65-3.12,7.92-5.2,12.3-7.04
	c5.44-2.27,10.81-4.69,16.35-6.71c0.86-0.31,1.74-0.6,2.64-0.76c2.13-0.38,3.25,0.55,3.43,2.71c0.16,1.95-0.3,3.8-0.89,5.63
	c-0.91,2.85-2.22,5.55-3.41,8.28c-1.77,4.06-3.25,8.22-4.19,12.56c-1.21,5.56-3.61,10.62-6.64,15.4c-0.3,0.48-0.61,0.91-0.46,1.54
	c1.22,5.1,0.05,9.83-2.05,14.51c-2.09,4.64-4.56,9.03-7.45,13.21c-1.02,1.47-2.08,2.93-2.58,4.69c-0.59,2.09,0.11,2.93,2.22,2.57
	c2.25-0.38,4.36-1.26,6.53-1.91c1.76-0.53,3.51-1.07,5.36-1.15c2.58-0.12,4.26,0.97,5.13,3.42c0.72,2.02,0.78,4.14,0.81,6.26
	c0.16,13.62,0.32,27.24,0.48,40.86c0.04,3.11,0.11,6.23,0.15,9.34c0.02,1.65-0.81,2.9-2.02,3.89c-0.93,0.76-2.02,0.61-3.1-0.48
	c-1.52-1.53-2.97-3.13-4.4-4.74c-5.35-5.99-10.54-12.11-16.34-17.67c-1.15-1.11-2.36-2.15-3.66-3.09c-0.64-0.47-1.3-0.93-2.1-1.11
	c-1.03-0.24-1.57,0.25-1.36,1.28c0.19,0.94,0.63,1.8,1.05,2.66c1.22,2.46,2.42,4.94,3.67,7.39c1.9,3.72,2.14,7.56,0.31,11.29
	c-1.15,2.35-0.52,3.98,1.14,5.6c1.1,1.08,2.31,2,3.76,2.5c1.91,0.66,3.18,1.96,3.98,3.73c0.93,2.07,1.9,4.16,1.38,6.54
	c-0.16,0.75,0.43,0.76,0.83,0.91c3.56,1.4,7.12,2.78,10.69,4.17c1.68,0.65,3.28,1.45,4.62,2.7c0.94,0.88,1.48,1.91,0.98,3.21
	c-0.49,1.3-1.52,1.91-2.86,1.99c-1.34,0.08-2.6-0.3-3.83-0.79c-3.77-1.5-7.55-2.99-11.31-4.53c-0.79-0.32-1.19-0.32-1.63,0.54
	c-1.08,2.09-2.35,4.07-3.83,5.92c-0.8,1-1.72,1.7-3.07,1.86c-1.18,0.14-2.33,0.55-3.5,0.76c-1.43,0.26-2.8,0.23-4.18-0.48
	c-2.35-1.2-4.94-1.24-7.49-1.04c-2.34,0.18-4.13-0.6-5.46-2.51c-0.64-0.92-1.49-1.51-2.58-1.81c-5.5-1.54-7.46-3.92-8.13-9.62
	c-0.3-2.57-0.21-5.1,0.43-7.6c0.16-0.64,0-0.9-0.62-1.13c-4.1-1.53-8.19-3.1-12.27-4.69c-0.67-0.26-0.87-0.04-1.12,0.52
	c-1.84,4.01-4.05,7.78-7.21,10.92c-1.03,1.02-2.14,1.91-3.41,2.61c-2.69,1.49-5.44,1.64-8.28,0.46c-4.62-1.92-8.47-4.95-11.32-8.96
	c-4.5-6.34-10.62-10.2-17.77-12.75c-1.16-0.41-2.28-0.94-3.42-1.41c-0.06-0.03-0.11-0.08-0.18-0.14c2.65,0,5.29-0.07,7.92,0.01
	c7.59,0.25,13.75,3.63,19.03,8.88c1.39,1.38,2.74,2.8,4.29,4c2.77,2.13,5.06,1.98,7.52-0.52c1.74-1.76,2.88-3.9,3.93-6.11
	c0.36-0.76-0.16-0.75-0.6-0.91c-6.62-2.43-13.17-5.04-19.68-7.73c-6.32-2.62-12.15-6.15-17.97-9.7c-6.09-3.71-12.04-7.64-18.1-11.77
	c1.46,0.06,2.68,0.45,3.92,0.73c10.13,2.32,20.29,4.56,30.17,7.85c5.84,1.94,11.24,4.89,16.85,7.36c2.88,1.27,5.73,2.59,8.57,3.95
	c0.74,0.35,1.02,0.23,1.27-0.54c0.46-1.37,0.84-2.75,1.01-4.18c0.53-4.75-1.95-7.78-6.83-8.48c-3.2-0.46-6.42-0.71-9.59-1.34
	c-4.38-0.87-8.31-2.7-11.96-5.22c-2.2-1.52-4.33-3.13-6.33-4.92C28.12,112.98,28.19,112.86,28.46,112.93z"/>
<path style={{fill: fill}}  d="M70.69,86.58c-2.43-0.05-3.76-1.04-3.86-2.87c-0.13-2.28,1.44-4.46,3.53-4.76c2.02-0.29,3.77,0.4,5.27,1.74
	c0.81,0.73,0.94,1.63,0.47,2.61C75.28,84.96,72.55,86.62,70.69,86.58z"/>
<path style={{fill: fill}}  d="M94.59,64.33c-0.01-4.14,3.16-6.45,6.63-4.83c1.45,0.68,1.98,1.73,1.67,3.31c-0.47,2.39-3,4.65-5.45,4.87
	C95.54,67.86,94.6,66.75,94.59,64.33z"/>
</svg>
);

export default LogoGILAB;
